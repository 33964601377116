export const ZHID = 0; //中文对应的languageId
export const CHNID = 100; //中国大陆对应的countryId
export const MAXSIZE = 3000; //后端接口每个page最大size
export const MAXSIZEDOWN = 10000; //后端接口每个page最大size
export const USERINFO = "droi.market.userInfo"; // 存储在localStorage里用户信息的key
export const LASTTIME = "droi.market.lastTIme"; // 存储在localStorage里最后一次点击的时间的key
export const POWERTREE = "droi.market.powerTree";
export const TIMEOUT = 2 * 60 * 60 * 1000; //超时时间2h

export const countryList = ["美国", "土耳其", "日本"];

export const booleanList = [
  { key: 1, value: "是" },
  { key: 0, value: "否" },
];

// 上架类型
export const releaseTypeList = [
  { key: 1, value: "普通" },
  // { key: 2, value: "预约" },
  // { key: 3, value: "内测" },
];

// 应用审核状态  状态 0、待爬取 1、待审核 2、已上架 3、已下架 4、审核不通过
export const resCheckStatusList = [
  // { key: 0, value: "待爬取", tag: "info" },
  { key: 1, value: "待审核", tag: "warning" },
  { key: 2, value: "已上架", tag: "success" },
  { key: 3, value: "已下架", tag: "danger" },
  { key: 4, value: "审核不通过", tag: "danger" },
];

// 应用上架状态
export const appStatusList = [
  { key: 1001, value: "未上架", tag: "info" },
  { key: 1002, value: "待上架", tag: "info" },
  { key: 1003, value: "已上架", tag: "success" },
  { key: 1004, value: "管理员下架", tag: "danger" },
  { key: 1005, value: "开发者下架", tag: "danger" },
  { key: 1007, value: "预约暂存", tag: "info" },
];

// 应用子类型
export const appSubTypeList = [
  { key: 1, value: "休闲游戏" },
  { key: 2, value: "网络游戏" },
];
// 应用来源
export const sourceList = [
  { key: 0, value: "开发者平台" },
  { key: 1, value: "管理运营平台" },
  // { key: 2, value: "系统抓取" },
  // { key: 3, value: "合作方同步" },
];
// 应用审核状态
export const appReviewStatusList = [
  { key: 1, value: "未审核", tag: "info" },
  { key: 2, value: "机审待审核", tag: "warning" },
  { key: 3, value: "机审不通过", tag: "danger" },
  { key: 4, value: "人工待审核", tag: "warning" },
  { key: 5, value: "人审不通过", tag: "danger" },
  { key: 6, value: "版本审核通过", tag: "success" },
];
// 应用类型
export const appTypeList = [
  { key: 2, value: "应用" },
  { key: 1, value: "游戏" },
];
//审核状态
export const auditTypeList = [
  { key: 900, value: "应用认领审核中" },
  { key: 901, value: "应用认领审核通过" },
  { key: 902, value: "应用认领审核不通过" },
  // { key: 2, value: "机审待审核" },
  // { key: 3, value: "机审不通过" },
  // { key: 4, value: "人工待审核" },
  // { key: 6, value: "人工审核通过" },
  // { key: 5, value: "人工审核不通过" },
];
// 认领审核状态
export const claimStatusList = [
  { key: 900, value: "应用认领审核中", tag: "info" },
  { key: 901, value: "应用认领审核通过", tag: "success" },
  { key: 902, value: "应用认领审核不通过", tag: "danger" },
  // { key: 230, value: "人工待审核", tag: "warning" },
  // { key: 301, value: "认领审核通过", tag: "danger" },
  // { key: 101, value: "版本审核通过", tag: "warning" },
];

// 收费类型
export const tariffTypeList = [
  { key: "9", value: "付费下载" },
  { key: "1", value: "激活收费" },
  { key: "2", value: "道具收费" },
  { key: "3", value: "关卡收费" },
  { key: "4", value: "购买虚拟币" },
  { key: "5", value: "部分章节收费（图书阅读类）" },
  { key: "6", value: "课程收费" },
  { key: "7", value: "会员收费" },
  { key: "8", value: "其他" },
];

// 年龄分级标准
export const ageLevelList = [
  {
    key: 3,
    level: "3+",
    value: "3岁以上",
    desc: "具有PEGI 3等级的游戏内容被认为适合所有年龄段。 游戏中未出现任何可能惊吓幼儿的声音或画面。 允许极其轻微的暴力形式（喜剧环境或儿童环境）。 不应出现粗俗语言。",
  },
  {
    key: 7,
    level: "7+",
    value: "7岁以上",
    desc: "可能包含会惊吓年幼儿的场景或声音的游戏内容应归为此类。 对于PEGI 7等级的游戏，允许非常轻微的暴力形式（暗示，非详细或非现实性）。",
  },
  {
    key: 12,
    level: "12+",
    value: "12岁以上",
    desc: "表现出较高度的幻想暴力，或对类人角色的非现实暴力行为的视频游戏，将属于这一年龄段。 可能存在性暗示或性姿势，而该类别中的任何不良语言都必须是轻微的。 也可以在赌场中进行仿真赌博（例如，在现实生活中会以金钱为目的的纸牌游戏）。",
  },
  {
    key: 16,
    level: "16+",
    value: "16岁以上",
    desc: "一旦描述的暴力（或性行为）达到了与现实生活中预期的阶段相同的水平，就会应用此评级。 在PEGI 16等级的游戏中使用不良语言可能更甚，而偶然性游戏以及使用烟酒，毒品的画面也可能存在。",
  },
  {
    key: 18,
    level: "18+",
    value: "18岁以上",
    desc: "当暴力程度极高，明显是无动机的杀戮或针对无防卫能力角色的暴力的阶段时，将应用PEGI 18分类。 吸毒和使用露骨的性行为亦归入这一分级。",
  },
];
// 开发者认证类型
export const identityTypeList = [
  { key: 1, value: "个人" },
  { key: 2, value: "企业" },
];
// 开发者账号状态
export const devStatusList = [
  // { key: 0, value: "使用中", tag: "success" },
  // { key: 1, value: "待认证", tag: "info" },
  // { key: 2, value: "冻结中", tag: "danger" },
  // { key: 3, value: "已注销", tag: "danger" },
  // { key: 4, value: "审核不通过", tag: "danger" },
  // { key: 5, value: "待审核(上级)", tag: "info" },
  // { key: 6, value: "上级审批不通过", tag: "error" },
  { key: 0, value: "使用中", tag: "success" },
  { key: 1, value: "待认证", tag: "info" },
  { key: 2, value: "冻结中", tag: "danger" },
  { key: 3, value: "已注销", tag: "danger" },
  { key: 4, value: "注销待审核", tag: "danger" },
  // { key: 5, value: "待审核(上级)", tag: "info" },
  // { key: 6, value: "上级审批不通过", tag: "error" },
];
// 开发者实名认证状态
export const verifiedCheckStatusTypeList = [
  { key: 1, value: "待认证", tag: "info" },
  { key: 2, value: "待审核", tag: "info" },
  { key: 3, value: "审核通过", tag: "success" },
  { key: 4, value: "审核不通过", tag: "danger" },
  { key: 5, value: "待审核(上级)", tag: "info" },
  { key: 6, value: "上级审批不通过", tag: "error" },
];
//预约审核状态
export const appointmentAduitList = [
  { key: 600, value: "预约待审核", tag: "info" },
  { key: 601, value: "预约审核通过-待生效", tag: "success" },
  { key: 611, value: "预约审核通过-已生效", tag: "info" },
  { key: 602, value: "预约审核不通过", tag: "danger" },
  { key: 603, value: "预约已失效", tag: "danger" },
  { key: 604, value: "预约暂存", tag: "success" },
  { key: 620, value: "更新待审核", tag: "danger" },
  { key: 621, value: "更新审核通过-待生效", tag: "success" },
  { key: 631, value: "更新审核通过-已生效", tag: "success" },
  { key: 632, value: "更新审核不通过", tag: "danger" },
  { key: 640, value: "预约下架待审核", tag: "danger" },
  { key: 641, value: "预约下架审核通过", tag: "success" },
  { key: 642, value: "预约下架审核不通过", tag: "danger" },
  { key: 643, value: "被管理后台下架", tag: "danger" },
];
//内测审核状态
export const closedBetaAduitList = [
  { key: 700, value: "内测待审核", tag: "info" },
  { key: 701, value: "内测审核通过-待生效", tag: "success" },
  { key: 711, value: "内测审核通过-已生效", tag: "success" },
  { key: 702, value: "内测审核不通过", tag: "danger" },
  { key: 703, value: "内测已失效", tag: "danger" },
  { key: 111, value: "开发者已撤销", tag: "danger" },
  { key: 721, value: "开发者下架", tag: "danger" },
  { key: 722, value: "被管理后台下架", tag: "danger" },
];
//首发审核状态
export const startingAduitList = [
  { key: 800, value: "首发待审核", tag: "info" },
  { key: 801, value: "首发审核通过", tag: "success" },
  { key: 802, value: "首发审核不通过", tag: "danger" },
  { key: 111, value: "取消", tag: "danger" },
];
//信息审核状态
export const infoAduitList = [
  { key: "0", value: "信息待审核", tag: "info" },
  { key: "1", value: "信息审核通过", tag: "success" },
  { key: "2", value: "信息审核不通过", tag: "danger" },
];
//版本审核状态
export const versionAduitList = [
  { key: "20", value: "机器待审核", tag: "info" },
  { key: "21", value: "机器审核通过", tag: "success" },
  { key: "22", value: "机器审核不通过", tag: "danger" },
  { key: "0", value: "人工待审核", tag: "success" },
  { key: "1", value: "人工审核通过", tag: "success" },
  { key: "2", value: "人工审核不通过", tag: "danger" },
  { key: "40", value: "回退待审核", tag: "info" },
  { key: "41", value: "回退审核通过", tag: "success" },
  { key: "42", value: "回退审核不通过", tag: "danger" },
  { key: "50", value: "下架待审核", tag: "info" },
  { key: "51", value: "下架审核通过", tag: "success" },
  { key: "52", value: "下架审核不通过", tag: "danger" },
];
// 商务审核
export const commentStatus = [
  { key: 100, value: "待审核", tag: "info" },
  { key: 101, value: "审核通过", tag: "success" },
  { key: 102, value: "审核不通过", tag: "danger" },
];
// 投放平台应用审核（商务审核）
export const commentPutinStatus = [
  { key: 1, value: "待审核", tag: "info" },
  { key: 2, value: "审核不通过", tag: "danger" },
  { key: 3, value: "审核通过", tag: "success" },
];
//合同管理页面 审核状态
export const contractAuditList = [
  { key: 0, value: "待提交", tag: "info" },
  { key: 1, value: "待审核", tag: "info" },
  { key: 2, value: "审核通过-待签署", tag: "info" },
  { key: 3, value: "签约驳回", tag: "danger" },
  // { key: 4, value: "运营待审核", tag: "info" },
  // { key: 5, value: "签署驳回", tag: "danger" },
  // { key: 6, value: "运营审核通过", tag: "success" },
  // { key: 7, value: "运营驳回", tag: "danger" },
  { key: 6, value: "审核通过", tag: "success" },
  { key: 7, value: "签署驳回", tag: "danger" },
];
// 合同签约方式
export const contractTypeList = [
  { key: 1, value: "线上签约" },
  { key: 2, value: "商务对接" },
];
// 合同生效状态
export const contractStatusList = [
  { key: 1, value: "合同生效中", tag: "success" },
  { key: 3, value: "合同已失效", tag: "danger" },
  { key: 2, value: "合同未生效", tag: "info" },
];
//合作模式
export const collaborateTypeList = [
  { key: 1, value: "CPA" },
  { key: 2, value: "CPS" },
  { key: 3, value: "CPD" },
  { key: 4, value: "CPC" },
  { key: 5, value: "耀火计划" },
  { key: 6, value: "CPT" },
];
// 开票内容类型
export const contentTypeList = [
  { key: 1, value: "信息服务费" },
  { key: 2, value: "信息费" },
];
// 发票类型
export const invoiceTypeList = [
  { key: 1, value: "增值税普通发票" },
  { key: 2, value: "增值税专用发票" },
];
//开发者审核状态&&开票审核状态
export const developerAduitList = [
  { key: 1, value: "待审核", tag: "info" },
  { key: 2, value: "审核通过", tag: "success" },
  { key: 3, value: "审核不通过", tag: "danger" },
];
//激励类型
export const incentiveTypeList = [
  // { key: 0, value: "新客户赠送" },
  { key: 5, value: "续充值" },
  { key: 4, value: "代理首充" },
  { key: 3, value: "直客首充" },
  // { key: 2, value: "新客户充值赠送" },
  { key: 1, value: "充值赠送" },
  { key: 6, value: "新客续充值" },
  { key: 7, value: "月消耗返利" },
  { key: 8, value: "月消耗增长返利" },
  { key: 9, value: "新客首充" },
];
//激励状态
export const activeStatusList = [
  { key: 0, value: "未开始", tag: "info" },
  { key: 1, value: "已开始", tag: "success" },
  { key: 2, value: "已结束", tag: "danger" },
];
export const financeInfoAuditList = [
  { key: 0, value: "待提交", tag: "info" },
  { key: 1, value: "待审核", tag: "info" },
  { key: 2, value: "审核通过", tag: "success" },
  { key: 3, value: "审核不通过", tag: "danger" },
  { key: 4, value: "一级待审批", tag: "info" },
  { key: 5, value: "一级审批不通过", tag: "error" },
];
export const originalityShowTypes = [
  { label: "开屏大图", value: 1 },
  { label: "横向大图", value: 2 },
  { label: "ICON", value: 3 },
];

//创意状态类型
export const originalityStatus = [
  // { label: "待审核", value: 0, tag: "info", key: 0 },
  // { label: "审核通过", value: 1, tag: "success", key: 1 },
  // { label: "审核不通过", value: 2, tag: "danger", key: 2 },
  // { label: "停用", value: 3, tag: "info", key: 3 },
  { label: "待审核", value: 2, tag: "info", key: 2 },
  { label: "审核通过", value: 1, tag: "success", key: 1 },
  { label: "审核不通过", value: 3, tag: "danger", key: 3 },
];
// 出价波动预警-场景类型
export const sceneTypeList = [
  // { key: 1, value: "推荐" },
  // { key: 2, value: "搜索" },
  { key: 1, value: "搜索" },
  { key: 2, value: "推荐位" },
  { key: 3, value: "首页焦点图" },
  { key: 4, value: "装机必备" },
  { key: 5, value: "开屏" },
];
// 出价波动预警-等级
export const gradeTypeList = [
  { key: 1, value: "搜索一级" },
  { key: 2, value: "搜索二级" },
  { key: 3, value: "搜索三级" },
  { key: 4, value: "搜索四级" },
];
// 推荐版位类型；propKey--对应的场景类型key
export const sellPositionList = [
  { key: 1, value: "应用开屏", propKey: 1 },
  { key: 2, value: "首页焦点图", propKey: 1 },
  { key: 3, value: "智能推荐", propKey: 1 },
  { key: 4, value: "精品推荐", propKey: 1 },
  { key: 5, value: "搜索结果页", propKey: 2 },
];
//结算方式
export const settlementTypeList = [
  { key: 1, value: "CPA" },
  { key: 2, value: "CPS" },
  { key: 3, value: "CPD" },
  { key: 5, value: "耀火计划" },
  { key: 6, value: "CPT" },
];
// 支持设备
export const supportedDeviceList = [
  { key: 1, value: "手机" },
  { key: 2, value: "平板电脑" },
];
// 安卓版本
export const versionList = [
  { key: 10, value: "Android 2.3.4,2.3.3" },
  { key: 11, value: "Android 3.0.x " },
  { key: 12, value: "Android 3.1.x" },
  { key: 13, value: "Android 3.2" },
  { key: 14, value: "Android 4.0, 4.0.1, 4.0.2" },
  { key: 15, value: "Android 4.0.3, 4.0.4" },
  { key: 16, value: "Android 4.1, 4.1.1" },
  { key: 17, value: "Android 4.2, 4.2.2" },
  { key: 18, value: "Android 4.3" },
  { key: 19, value: "Android 4.4" },
  { key: 20, value: "Android 4.4W" },
  { key: 21, value: "Android 5.0" },
  { key: 22, value: "Android 5.1" },
  { key: 23, value: "Android 6.0" },
  { key: 24, value: "Android 7.0" },
  { key: 25, value: "Android 7.1,7.1.1" },
  { key: 26, value: "Android 8.0" },
  { key: 27, value: "Android 8.1" },
  { key: 28, value: "Android 9" },
  { key: 29, value: "Android 10" },
  { key: 30, value: "Android 11" },
  { key: 32, value: "Android 12" },
  { key: 33, value: "Android 13" },
  { key: 34, value: "Android 14" },
  { key: 35, value: "Android 15" },
];
export const costTypeList = [
  { key: 1, value: "CPD" },
  { key: 2, value: "CPC" },
];
export const sourceTypeList = [
  { key: "", value: "全部流量" },
  { key: 1, value: "市场流量" },
  { key: 2, value: "生态流量" },
];
export const recomLabelTypeList = [
  // { key: 1, value: "市场流量-增加曝光" },
  // { key: 2, value: "市场流量-提高转化" },
  // { key: 3, value: "市场流量-提高知名度" },
  // { key: 101, value: "生态流量-增加曝光" },
  // { key: 102, value: "生态流量-提高转化" },
  // { key: 103, value: "生态流量-提高知名度" },
  { key: "", value: "全部" },
  { key: 1, value: "市场流量-增加曝光" },
  { key: 2, value: "市场流量-提高转化" },
  { key: 3, value: "市场流量-提高知名度" },
  // { key: 1, value: "增加曝光" },
  // { key: 2, value: "提高转化" },
  // { key: 3, value: "提高知名度" },
];
export const recomLabelTypeList1 = [
  { key: "", value: "全部" },
  { key: 101, value: "生态流量-增加曝光" },
  { key: 102, value: "生态流量-提高转化" },
  { key: 103, value: "生态流量-提高知名度" },
  // { key: 101, value: "增加曝光" },
  // { key: 102, value: "提高转化" },
  // { key: 103, value: "提高知名度" },
];
export const recomLabelTypeList2 = [
  { key: "", value: "全部" },
  { key: 1, value: "市场流量-增加曝光" },
  { key: 2, value: "市场流量-提高转化" },
  { key: 3, value: "市场流量-提高知名度" },
  { key: 101, value: "生态流量-增加曝光" },
  { key: 102, value: "生态流量-提高转化" },
  { key: 103, value: "生态流量-提高知名度" },
];
//报表页面 环境
export const environmentList = [
  { label: "测试", value: 1 },
  { label: "正式", value: 0 },
];
//报表页面 出价模式
export const collaborateList = [
  { label: "CPD", value: 1 },
  // { label: "CPC", value: 2 },
  // { label: "CPV", value: 3 },
  { label: "CPA", value: 4 },
];
// 报表页面场景类型
export const sceneList = [
  { value: "", label: "全部场景" },
  { value: 0, label: "市场" },
  { value: 1, label: "桌面文件夹" },
  { value: 2, label: "管家" },
  { value: 3, label: "飞觅浏览器" },
];
export const sceneArrayList = [
  { value: 0, label: "市场" },
  { value: 1, label: "桌面文件夹" },
  { value: 2, label: "管家" },
  { value: 3, label: "飞觅浏览器" },
];
